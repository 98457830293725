// NOTE: separate component for GLPs to prevent loading translation files
import React from "react" 

import { imgCompMap } from "./imgMap"
import StepsToProtectBusiness from "./StepsToProtectBusiness"

const data = [
  {
    image: imgCompMap.enterDetails,
    title: "Enter Your Details",
    desc: "Your contact details and  business details"
  },
  {
    image: imgCompMap.scheduleCall,
    title: "Schedule A Call",
    desc: "Book an appointment with our Advisor"
  },
  {
    image: imgCompMap.talkToAdvisor,
    title: "Talk to Advisor",
    desc: "Get advice on what is best for your business"
  },
  {
    image: imgCompMap.chooseInsurer,
    title: "Choose insurer",
    desc: "Pick the insurer best for your business protection from the different options"
  },
  {
    image: imgCompMap.protectBusiness,
    title: "Protect your business",
    desc: "Congrats! Your business is now safe from 15+ risks"
  },
]

const StepsToProtectBusinessMLP = ({style={}, desktopStyles={}, mobileStyles={}}) => {
  return (
    <StepsToProtectBusiness title="5 simple steps to protect your business" data={data} style={style} desktopStyles={desktopStyles} mobileStyles={mobileStyles} />
  )
}

export default StepsToProtectBusinessMLP